<template>
  <v-card class="mx-auto mt-5" elevation="4" max-width="400">
    <v-card-title class="justify-center">Seminar Order Complete</v-card-title>
    <v-icon
      style="font-size:150px; text-align:center; width:100%"
      >
      mdi-checkbox-marked-circle-outline
    </v-icon>
    <br />
    <p class="text">
      Your order has been placed. An email with the next steps has been sent.
    </p>

    <v-row>
      <v-col>
        <div style="text-align: center; width:100%">
          <router-link to="/">Return Home</router-link>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<style scoped>
.text {
  text-align: center;
  padding: 3%;
}
.v-card__title {
  text-align: center;
  width: 100%;
}
.img {
  display: block;
  margin-left: auto;
  margin-right: auto;

  aspect-ratio: 1;
  size: contain;
}

.v-application a {
  text-align: center;
  width: 100%;
  text-decoration: none;
  color: #22abe1;
  display: flex;
  justify-content: center;
}
</style>

<script>
export default {
  name: "SeminarComplete",
  mounted: function() {},
};
</script>
